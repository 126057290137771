import { ActivityAthlete, Team, TeamsApi } from 'oas';
import { mockResponse, useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetTeams = (...parameters: Parameters<TeamsApi['getAllTeams']>): UseQueryResult<Team[]> => {
    return useApiQuery(
        ['teams', parameters],
        (apiConfig, axiosInstance) => new TeamsApi(apiConfig, '', axiosInstance).getAllTeams(...parameters),
        {
            staleTime: 3600000,
        },
    );
};

export const useDefaultTeam = (): Team | undefined => {
    const teams = useGetTeams();

    return teams.data?.find((team) => team.is_default);
};

export const useGetTeam = (teamId: string): Team | undefined => {
    const teams = useGetTeams();

    if (!teamId) {
        return undefined;
    }

    return teams.data?.find((team) => team.id === teamId);
};

export const useGetAthletesForTeam: (
    ...parameters: Parameters<TeamsApi['getAthletesForTeam']>
) => UseQueryResult<Array<ActivityAthlete>> = (id, options = {}) => {
    return useApiQuery(
        ['teams', id, 'athletes'],
        (apiConfig, axiosInstance) => {
            return id !== ''
                ? new TeamsApi(apiConfig, '', axiosInstance).getAthletesForTeam(id, options)
                : mockResponse([]);
        },
        {
            staleTime: 3600000,
        },
    );
};
