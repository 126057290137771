import { useAuth } from 'auth';
import * as React from 'react';
import { BaseLayout } from '.';
import { MainHeader } from './main-header';
import styles from './main-layout.module.css';

// This is the layout used when logged in (timeline, downloads page etc)
export const MainLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { indeterminate, isAuthenticated } = useAuth();
    const isLoading = indeterminate || !isAuthenticated;

    return (
        <BaseLayout isLoading={isLoading}>
            <MainHeader />
            <div className={styles['scrolling-container']}>{children}</div>
        </BaseLayout>
    );
};
