import { useState } from 'react';
import { useLinkParams } from '../use-query-params';
import { DateRange } from './activity-side-menu-filters';

interface SideMenuFilterState {
    textFilter: string;
    setTextFilter: (filter: string) => void;
    dateFilters: DateRange;
    setDateFilters: (dates: DateRange) => void;
    tagFilters: string[];
    setTagFilters: (tagFilters: string[]) => void;
}
export const useSideMenuFilterState = (): SideMenuFilterState => {
    const { search, dateFrom, dateTo, tags } = useLinkParams();

    // Local states
    const [textFilter, setTextFilter] = useState<string>(search ?? '');
    const [dateFilters, setDateFilters] = useState<DateRange>({ from: dateFrom, to: dateTo } as unknown as DateRange);
    const [tagFilters, setTagFilters] = useState<string[]>(tags);

    return { textFilter, setTextFilter, dateFilters, setDateFilters, tagFilters, setTagFilters };
};
