import * as React from 'react';
import { useHasFeature } from 'api';
import { PermissionGuard, useAuth, usePermission, useAuthUserGuard, UserPermissionsEnum } from 'auth';
import { FrontendConfigFeaturesEnum } from 'oas';
import { Redirect } from 'react-router-dom';
import Timeline from './timeline';
import { useReportingRedirectStore } from 'shared/state/reporting/reportingRedirectStore';

const Home: React.FC = () => {
    // Hooks
    const redirectUri = useAuthUserGuard();
    const { indeterminate } = useAuth();
    const hasTimelinePermission = usePermission(UserPermissionsEnum.Timeline);
    const showTimeline = useHasFeature(FrontendConfigFeaturesEnum.ShowTimeline) && hasTimelinePermission;
    const showVectorAppWelcome = usePermission(UserPermissionsEnum.VectorAppWelcome);
    const showReporting = useHasFeature(FrontendConfigFeaturesEnum.ShowReporting);
    const { isReportingRedirect } = useReportingRedirectStore();

    // Don't render anything if the user is not logged in or the auth state is indeterminate
    if (indeterminate || redirectUri === undefined) {
        return <></>;
    }

    // Redirect to the appropriate page if the user is not authorized
    if (redirectUri) {
        return <Redirect to={redirectUri} />;
    }

    if (!showTimeline) {
        if (showVectorAppWelcome) {
            return <Redirect to="/welcome/vector-app" />;
        }

        if (showReporting) {
            return <Redirect to="/reporting" />;
        }
    }

    // When a customer has both reporting and timeline enabled, redirect to reporting on initial load
    if ((isReportingRedirect && showReporting) || (!showTimeline && showReporting)) {
        return <Redirect to="/reporting" />;
    }

    return (
        <PermissionGuard permission={UserPermissionsEnum.Timeline}>
            <Timeline />
        </PermissionGuard>
    );
};

export default Home;
