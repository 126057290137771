import { Button, Dialog, DialogProps, Spinner, ThemeProvider, Typography } from '@SBGSports/referee-react';
import { useTranslations } from 'i18n';
import { useState } from 'react';

interface ConfirmationModalProps {
    handleClose: (isOpen: boolean) => void;
    handleConfirm: () => void;
    dialogProps: DialogProps;
    message: string;
    awaitingMessage?: string;
    isOpen: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    const {
        handleClose,
        handleConfirm,
        dialogProps,
        message,
        awaitingMessage,
        isOpen,
        okButtonText,
        cancelButtonText,
    } = props;
    const { __ } = useTranslations();
    const [confirm, setConfirm] = useState(false);

    return (
        <ThemeProvider theme="light">
            <Dialog
                size={dialogProps.size ?? 'medium'}
                header={dialogProps.header}
                open={isOpen}
                onChange={() => handleClose(false)}
            >
                <Dialog.Content>
                    {confirm && awaitingMessage ? (
                        <>
                            <div
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography>{awaitingMessage}</Typography>
                                <Spinner />
                            </div>
                        </>
                    ) : (
                        <>
                            <Typography>{message}</Typography>
                            <Dialog.Footer>
                                <Button variant="text" onClick={() => handleClose(false)}>
                                    {cancelButtonText ?? __('misc.confirm_no')}
                                </Button>
                                <Button
                                    data-testId={'confirm-btn'}
                                    onClick={() => {
                                        setConfirm(true);
                                        handleConfirm();
                                    }}
                                >
                                    {okButtonText ?? __('misc.save_changes')}
                                </Button>
                            </Dialog.Footer>
                        </>
                    )}
                </Dialog.Content>
            </Dialog>
        </ThemeProvider>
    );
};
