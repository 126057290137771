import { MATCH_DAY } from 'reporting/constants';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface ComparisonStore {
    comparison: string;
    setComparison: (reportType: string) => void;
}

export const useComparisonStore = create<ComparisonStore>()(
    immer((set) => ({
        comparison: MATCH_DAY,

        setComparison: (comparison: string) =>
            set((state: { comparison: string }) => {
                state.comparison = comparison;
            }),
    })),
);
