import * as React from 'react';
import { AlertProvider, RefereeProvider } from '@SBGSports/referee-react';
import '@SBGSports/referee-react/reset.css';
import '@SBGSports/referee-react/styles.css';
import { CssBaseline, ThemeProvider as MuiThemeProvider, makeStyles } from '@material-ui/core';
import { ObservabilityProvider } from 'analytics';
import { AuthProvider, PermissionGuard, UserPermissionsEnum } from 'auth';
import 'bootstrap';
import { I18nProvider } from 'i18n';
import { SnackbarProvider } from 'notistack';
import { FrontendConfigFeaturesEnum } from 'oas';
import { Logout } from 'pages';
import LoginPage from 'pages/login';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import 'styles/globals.css';
import 'styles/globals.less';
import { lightTheme } from 'theme';
import FeatureEnabledRoute from './feature-enabled-route';
import { BaseLayout } from 'layout';

const queryClient = new QueryClient();

const Auth = React.lazy(() => import('pages/auth'));
const Download = React.lazy(() => import('pages/download'));
const League = React.lazy(() => import('pages/league'));
const Registration = React.lazy(() => import('pages/registration'));
const VectorAppWelcome = React.lazy(() => import('pages/vector-app-welcome'));
const Eula = React.lazy(() => import('pages/eula'));
const Error404 = React.lazy(() => import('pages/error-404'));
const Home = React.lazy(() => import('pages/home'));
const ActivityEditor = React.lazy(() => import('pages/activity-editor'));
const Reporting = React.lazy(() => import('pages/reporting'));
const MyAccount = React.lazy(() => import('pages/my-account'));
const Settings = React.lazy(() => import('pages/settings'));

const App: React.FC = () => {
    const useSnackbarStyles = makeStyles(() => ({
        containerAnchorOriginTopLeft: { top: '74px' },
        containerAnchorOriginTopRight: { top: '74px' },
        variantSuccess: { backgroundColor: lightTheme.palette.success.main },
        variantError: { backgroundColor: lightTheme.palette.error.main },
        variantWarning: { backgroundColor: lightTheme.palette.warning.main },
        variantInfo: { backgroundColor: lightTheme.palette.info.main },
    }));

    const components = {
        Link,
    };

    return (
        <>
            <RefereeProvider theme="light" components={components}>
                <MuiThemeProvider theme={lightTheme}>
                    <SnackbarProvider maxSnack={3} classes={useSnackbarStyles()}>
                        <HelmetProvider>
                            <Helmet>
                                <meta name="build-info" content={process.env.REACT_APP_BUILD_NUMBER} />
                                <meta name="theme-color" content={lightTheme.palette.primary.main} />
                            </Helmet>
                            <CssBaseline />
                            <AlertProvider>
                                <BrowserRouter>
                                    <Switch>
                                        <Route path="/logout">
                                            <Logout />
                                        </Route>
                                        <Route>
                                            <QueryClientProvider client={queryClient}>
                                                <AuthProvider>
                                                    <ObservabilityProvider>
                                                        <I18nProvider>
                                                            <BrowserRouter>
                                                                <React.Suspense
                                                                    fallback={<BaseLayout isLoading={true} />}
                                                                >
                                                                    <Switch>
                                                                        <Route path="/" exact>
                                                                            <Home />
                                                                        </Route>
                                                                        <FeatureEnabledRoute
                                                                            feature={
                                                                                FrontendConfigFeaturesEnum.ShowEditor
                                                                            }
                                                                            path="/editor/:activityId?"
                                                                        >
                                                                            <ActivityEditor />
                                                                        </FeatureEnabledRoute>
                                                                        <FeatureEnabledRoute
                                                                            feature={
                                                                                FrontendConfigFeaturesEnum.ShowDownloads
                                                                            }
                                                                            path="/download"
                                                                            exact
                                                                        >
                                                                            <PermissionGuard
                                                                                permission={
                                                                                    UserPermissionsEnum.Download
                                                                                }
                                                                            >
                                                                                <Download />
                                                                            </PermissionGuard>
                                                                        </FeatureEnabledRoute>
                                                                        <Route path="/settings">
                                                                            <PermissionGuard
                                                                                permission={
                                                                                    UserPermissionsEnum.Settings
                                                                                }
                                                                            >
                                                                                <Settings />
                                                                            </PermissionGuard>
                                                                        </Route>
                                                                        <Route path="/login">
                                                                            <LoginPage />
                                                                        </Route>
                                                                        <Route path="/auth/:page/:token?">
                                                                            <Auth />
                                                                        </Route>
                                                                        <Route path="/registration">
                                                                            <Registration />
                                                                        </Route>
                                                                        <Route path="/welcome/vector-app" exact>
                                                                            <VectorAppWelcome />
                                                                        </Route>
                                                                        <Route path="/league">
                                                                            <League />
                                                                        </Route>
                                                                        <Route path="/eula" exact>
                                                                            <Eula />
                                                                        </Route>
                                                                        <FeatureEnabledRoute
                                                                            feature={
                                                                                FrontendConfigFeaturesEnum.ShowReporting
                                                                            }
                                                                            path="/reporting/:type?/:activityId?"
                                                                        >
                                                                            <Reporting />
                                                                        </FeatureEnabledRoute>
                                                                        <Route path="/myaccount" exact>
                                                                            <MyAccount />
                                                                        </Route>
                                                                        <Route>
                                                                            <Error404 />
                                                                        </Route>
                                                                    </Switch>
                                                                </React.Suspense>
                                                            </BrowserRouter>
                                                        </I18nProvider>
                                                    </ObservabilityProvider>
                                                </AuthProvider>
                                                {false && <ReactQueryDevtools />}
                                            </QueryClientProvider>
                                        </Route>
                                    </Switch>
                                </BrowserRouter>
                            </AlertProvider>
                        </HelmetProvider>
                    </SnackbarProvider>
                </MuiThemeProvider>
            </RefereeProvider>
        </>
    );
};
export default App;
