import { StatRow, StatsApi } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';
import { StatsQuery } from './mutate';
import { transformStats } from './transform';
import { DEFAULT_BENCHMARK_STATS, REPORTING } from '../../reporting/constants';
import { ONE_HOUR } from 'shared';
export const useGetReportingStats = (
    parameters: StatsQuery,
    postTransform?: (stats: StatRow[]) => StatRow[],
): UseQueryResult<StatRow[]> => {
    const requestParameters = Object.keys(DEFAULT_BENCHMARK_STATS) // required for volume, intensity calculations
        .concat(REPORTING.duration) // required for intensity calculations
        .concat(parameters.statsRequest.parameters ?? []) // add additional params
        .filter((k) => !['volume', 'overall', 'intensity'].includes(k)); // remove unnecessary params if passed in
    return useApiQuery(
        [
            'stats',
            parameters.statsRequest.parameters,
            ...(parameters.statsRequest.filters ?? []),
            ...(parameters.statsRequest.group_by ?? []),
        ],
        (apiConfig, axiosInstance) =>
            new StatsApi(apiConfig, '', axiosInstance)
                .postStats({ ...parameters.statsRequest, parameters: requestParameters })
                .then((response) => {
                    if (postTransform) {
                        const result = transformStats(response.data, parameters.averageSets, parameters.statType);
                        const aggregateData = postTransform(result);
                        return {
                            ...response,
                            data: aggregateData,
                        };
                    }

                    return {
                        ...response,
                        data: transformStats(response.data, parameters.averageSets, parameters.statType),
                    };
                }),
        {
            staleTime: ONE_HOUR,
        },
    );
};
