import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface InitialLoadState {
    isReportingRedirect: boolean;
    setReportingRedirect: (isReportingRedirect: boolean) => void;
}

export const useReportingRedirectStore = create<InitialLoadState>()(
    persist(
        immer((set) => ({
            isReportingRedirect: true,

            setReportingRedirect: (isReportingRedirect: boolean) =>
                set((state: { isReportingRedirect: boolean }) => {
                    state.isReportingRedirect = isReportingRedirect;
                }),
        })),
        {
            name: 'report-redirect',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);
