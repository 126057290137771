import { parseJSON } from 'date-fns';
import { Activity, ActivityListItem, Athlete, Period, PeriodAthlete, TagItem, Team } from 'oas';

export interface TfActivity {
    id: ActivityListItem['id'];
    name: ActivityListItem['name'];
    period_count: ActivityListItem['period_count'];
    athlete_count: ActivityListItem['athlete_count'];
    duration: number;
    owner_id: ActivityListItem['owner_id'];
    owner_name?: string;
    is_deleted: ActivityListItem['is_deleted'];
    start_time: Date;
    end_time: Date;
    modified_at: Date;
    full_sync: boolean | null;
    is_injected?: Activity['is_injected'];
    isEditing?: boolean;
    originalName?: string;
    editable?: boolean;
    athletes?: Athlete[];
    home_team?: Team;
    teams?: Team[];
    periods?: TfPeriodInActivity[];
    tags?: TagItem[];
    activity_athletes?: ActivityListItem['activity_athletes'];
    venue_field_id?: string;
}

export interface TfPeriodInActivity {
    id: Period['id'];
    name: Period['name'];
    start_time: Date;
    end_time: Date;
    is_injected: number;
    period_athletes: PeriodAthlete[];
}

export const transformPeriod = (row: Period): TfPeriodInActivity => {
    return {
        id: row.id,
        name: row.name,
        start_time: new Date(row.start_time * 1000),
        end_time: new Date(row.end_time * 1000),
        is_injected: row.is_injected || 0,
        period_athletes: row.period_athletes || [],
    };
};

export const transformActivity = (row: Activity): TfActivity => {
    return {
        id: row.id,
        name: row.name,
        periods: (row.periods || []).map(transformPeriod),
        athletes: row.athletes,
        period_count: row.periods?.length || 0,
        athlete_count: row.athletes?.length || 0,
        start_time: new Date(row.start_time * 1000),
        end_time: new Date(row.end_time * 1000),
        modified_at: parseJSON(row.modified_at),
        duration: row.end_time - row.start_time,
        owner_id: row.owner_id || '',
        owner_name: row.owner?.name,
        home_team: row.home_team,
        teams: row.teams,
        is_injected: row.is_injected,
        is_deleted: 0,
        full_sync: null,
        tags: row.tags,
        venue_field_id: row.venue_field_id,
    };
};

export const transformActivityListItem = (row: ActivityListItem): TfActivity => {
    return {
        id: row.id,
        name: row.name,
        period_count: row.period_count,
        athlete_count: row.athlete_count,
        start_time: new Date(row.start_time * 1000),
        end_time: new Date(row.end_time * 1000),
        modified_at: parseJSON(row.modified_at),
        duration: row.end_time - row.start_time,
        owner_id: row.owner_id,
        owner_name: row.owner.name,
        is_deleted: row.is_deleted,
        full_sync:
            (row.actdic_status !== undefined &&
                row.actdic_status.sd !== undefined &&
                row.actdic_status.sd.munged !== undefined &&
                (row.actdic_status.sd.munged.synced || 0) > 0 &&
                row.actdic_status.sd.munged.unsynced === 0) ||
            null,
        isEditing: false,
        editable: false,
        originalName: row.name,
        tags: row.tag_list,
        activity_athletes: row.activity_athletes,
    };
};
