import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { StatsApi, StatRow, StatsRequest } from 'oas';
import { useApiMutation } from 'api/use-api-mutation';
import axios from 'axios';
import { transformStats } from './transform';
import { AverageSetsModel, StatType } from 'reporting/types';

export interface StatsQuery {
    statsRequest: StatsRequest;
    averageSets: AverageSetsModel;
    statType: StatType;
}

export const usePostStatsRequest = (): UseMutationResult<StatRow[], unknown, StatsQuery> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new StatsApi(apiConfig, '', axiosInstance).postStats(attr.statsRequest);

            if (axios.isAxiosError(response)) {
                throw response;
            }

            return transformStats(response.data, attr.averageSets, attr.statType);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['stats'],
                });
            },
        },
    );
};
