import * as React from 'react';
import { useAlerts } from '@SBGSports/referee-react';
import { Box, Typography } from '@material-ui/core';
import { useAuth } from 'auth';
import { useTranslations } from 'i18n';
import { MiniLayout } from 'layout';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { CentredSpinner, appTitle, getValidationErrors, useSnackbar } from 'shared';

const PasswordUpdateForm = React.lazy(() => import('./password-update-form'));

export const ResetPassword: React.FC = () => {
    // Hooks
    const { __ } = useTranslations();
    const { user, logout, updateEmailPassword, checkAuthentication } = useAuth();
    const history = useHistory();
    const { showAlert } = useAlerts();
    const { successSnackbar } = useSnackbar();

    const handleSubmit = async (...args: Parameters<typeof updateEmailPassword>) => {
        try {
            await updateEmailPassword(...args);
            successSnackbar(__('password_updated'));
            checkAuthentication();
            // Take user to main screen
            history.push('/login');
        } catch (responseError) {
            showAlert(...getValidationErrors(responseError), { variant: 'alert', autoClose: true });
        }
    };

    return (
        <>
            <MiniLayout narrow>
                <Helmet>
                    <title>{appTitle('Update password')}</title>
                </Helmet>
                {user && (
                    <React.Suspense fallback={<CentredSpinner variant="primary" />}>
                        <Box marginBottom={4}>
                            <Typography>{__('auth.provide_new_password', { username: user.name })}</Typography>
                        </Box>
                        <PasswordUpdateForm onSubmit={handleSubmit} onCancel={logout} />
                    </React.Suspense>
                )}
            </MiniLayout>
        </>
    );
};
export default ResetPassword;
