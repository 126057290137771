import * as React from 'react';
import { Spinner, Typography } from '@SBGSports/referee-react';
import { Box, ThemeProvider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useGetFrontendConfig } from 'api';
import { useAuth } from 'auth';
import { MiniLayout } from 'layout';
import { Helmet } from 'react-helmet-async';
import { HashRouter, Route, useHistory, useLocation } from 'react-router-dom';
import { appTitle } from 'shared';
import { darkTheme, lightTheme } from 'theme';
import { ForgotPasswordDialog } from './forgot-password-dialog';
import { UsernamePasswordForm } from './username-password-form';

export const UsernamePassword: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();
    const { data: frontendConfig } = useGetFrontendConfig();
    const showIcpLicense = frontendConfig?.app.aws_default_region === 'cn-north-1';
    const icpInformation = frontendConfig?.app.icp_information?.split(',');
    const loginInfoMessage = frontendConfig?.app.login_info_message;

    React.useEffect(() => {
        if (auth.user) {
            let destination = '/';
            if (location.search) {
                const params = new URLSearchParams(location.search);
                const intended = params.get('intended');
                if (intended && intended.startsWith('/')) {
                    destination = intended;
                }
            }
            history.push(destination);
        }
    }, [history, location, auth]);

    const handleSubmit = auth.login;

    const showForm = auth.csrfAuthd && !auth.user && !auth.indeterminate;

    const handleCloseDialog = () => {
        // history.push does not work by itself because of mixture of BrowserRouter and HashRouter
        window.location.href = '#';
        history.push({ hash: '' });
    };

    return (
        <>
            <MiniLayout narrow>
                <Helmet>
                    <title>{appTitle('Login')}</title>
                </Helmet>
                {!showForm && (
                    <div style={{ textAlign: 'center', position: 'absolute', width: '100%', paddingTop: '60px' }}>
                        <Spinner />
                    </div>
                )}
                <div
                    style={{
                        opacity: showForm ? 1 : 0,
                        transitionDuration: '150ms',
                        transitionProperty: 'opacity',
                    }}
                >
                    <ThemeProvider theme={darkTheme}>
                        <UsernamePasswordForm onSubmit={handleSubmit} />
                    </ThemeProvider>

                    {loginInfoMessage && (
                        <Box mt={3}>
                            <ThemeProvider theme={lightTheme}>
                                <Alert severity="info">
                                    <div style={{ paddingLeft: 10 }}>
                                        <Typography variant="label-2">{loginInfoMessage}</Typography>
                                    </div>
                                </Alert>
                            </ThemeProvider>
                        </Box>
                    )}
                    <Box>
                        <Box mt={3}>
                            <a href="#reset-password">Forgot password?</a>
                        </Box>
                        <Box mt={3} fontSize={9}>
                            By logging into OpenField, you acknowledge that you have read, understood and that you agree
                            to be bound by our{' '}
                            <a id="eulalink" href="/eula" target="blank">
                                EULA
                            </a>
                            .
                        </Box>

                        {showIcpLicense && icpInformation && (
                            <Box mt={3}>
                                <a href={icpInformation[0]} target="blank" style={{ textDecoration: 'underline' }}>
                                    {icpInformation[1]}
                                </a>
                                <br />
                                <a href={icpInformation[2]} target="blank" style={{ textDecoration: 'underline' }}>
                                    {icpInformation[3]}
                                </a>
                            </Box>
                        )}
                    </Box>
                </div>
            </MiniLayout>
            <HashRouter hashType="noslash">
                <Route path="/reset-password">
                    <ForgotPasswordDialog open={true} onClose={handleCloseDialog} />
                </Route>
            </HashRouter>
        </>
    );
};

export default UsernamePassword;
