import { useHasFeature, useUpdateNotifications } from 'api';
import { parseJSON } from 'date-fns';
import { useTranslations } from 'i18n';
import { ActionButton, DismissButton } from 'notifications/buttons';
import * as React from 'react';
import { NOTIFICATION_HELP_LINK_VECTOR_CORE, NOTIFICATION_HELP_LINK_VECTOR_PLUS } from 'shared';
import { NotificationView } from '../notification-view';
import { NotificationTypeProps } from './notification-type-props';
import { FrontendConfigFeaturesEnum } from 'oas';

export const Welcome: React.FC<NotificationTypeProps> = (props) => {
    const { notification, handleToggleRead } = props;

    const { __ } = useTranslations();
    const createdAt = parseJSON(notification.created_at);

    const { mutate: updateNotifications } = useUpdateNotifications();
    const hasShowReporting = useHasFeature(FrontendConfigFeaturesEnum.ShowReporting);

    const handleAction = React.useCallback(() => {
        updateNotifications({ ids: [notification.id], read: true });
        window.open(hasShowReporting ? NOTIFICATION_HELP_LINK_VECTOR_CORE : NOTIFICATION_HELP_LINK_VECTOR_PLUS);
        return false;
    }, [hasShowReporting, notification.id, updateNotifications]);

    return (
        <NotificationView
            title={__('notifications.welcome_title')}
            contents={__('notifications.welcome_body')}
            createdAt={createdAt}
            isRead={notification.read === 1}
            onToggleRead={handleToggleRead}
            actions={[
                <DismissButton key="dismiss" notification={notification} />,
                <ActionButton key="action" notification={notification} onClick={handleAction}>
                    {__('misc.tell_me_more')}
                </ActionButton>,
            ]}
        />
    );
};
