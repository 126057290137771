/* tslint:disable */
/* eslint-disable */
/**
 * Annotations service
 * imported from https://github.com/SBGSports/openfield-annotations-service/blob/main/api/openapi.yaml. Definitions remain same as described on annotation service except the need of customer id on each endpoint and health endpoint. API definition for internal Annotations service endpoints. Note that routes may appear in more than one of the categories listed below.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'activity_id': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'annotation_layer_id': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'period_id': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'annotation_category_id': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'name': string;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof Annotation
     */
    'start_time_ms': number;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof Annotation
     */
    'end_time_ms': number;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'modified_at': string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Annotation
     */
    'athlete_ids': Array<string>;
    /**
     * The ID of the annotation in the source system, e.g. Focus. Used for deduplication.
     * @type {string}
     * @memberof Annotation
     */
    'imported_id'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationCategory
 */
export interface AnnotationCategory {
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategory
     */
    'id': string;
    /**
     * Display name
     * @type {string}
     * @memberof AnnotationCategory
     */
    'display_name': string;
    /**
     * Import name
     * @type {string}
     * @memberof AnnotationCategory
     */
    'import_name': string;
    /**
     * Pre-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategory
     */
    'start_offset': number;
    /**
     * Post-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategory
     */
    'end_offset': number;
    /**
     * HTML color in hexadecimal format. The color to use for annotations of this type.
     * @type {string}
     * @memberof AnnotationCategory
     */
    'default_color': string;
    /**
     * false or true. annotation category should be baked. If is_event = true then is_bakeable will be false.
     * @type {boolean}
     * @memberof AnnotationCategory
     */
    'is_bakeable': boolean;
    /**
     * false or true. An event might be a single event in time (like a Goal Keeper Save) vs a period of time (like ball in possession)
     * @type {boolean}
     * @memberof AnnotationCategory
     */
    'is_event': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategory
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategory
     */
    'modified_at': string;
}
/**
 * 
 * @export
 * @interface AnnotationCategoryRequest
 */
export interface AnnotationCategoryRequest {
    /**
     * Display name
     * @type {string}
     * @memberof AnnotationCategoryRequest
     */
    'display_name': string;
    /**
     * Import name
     * @type {string}
     * @memberof AnnotationCategoryRequest
     */
    'import_name': string;
    /**
     * Pre-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategoryRequest
     */
    'start_offset': number;
    /**
     * Post-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategoryRequest
     */
    'end_offset': number;
    /**
     * HTML color in hexadecimal format. The color to use for annotations of this type.
     * @type {string}
     * @memberof AnnotationCategoryRequest
     */
    'default_color': string;
    /**
     * false or true annotation category should be baked. If is_event = true then is_bakeable will be false.
     * @type {boolean}
     * @memberof AnnotationCategoryRequest
     */
    'is_bakeable': boolean;
    /**
     * false or true. An event might be a single event in time (like a Goal Keeper Save) vs a period of time (like ball in possession)
     * @type {boolean}
     * @memberof AnnotationCategoryRequest
     */
    'is_event': boolean;
}
/**
 * 
 * @export
 * @interface AnnotationLayer
 */
export interface AnnotationLayer {
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayer
     */
    'activity_id': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayer
     */
    'annotation_category_id': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayer
     */
    'name': string;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof AnnotationLayer
     */
    'start_time_ms': number;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof AnnotationLayer
     */
    'end_time_ms': number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayer
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayer
     */
    'modified_at': string;
}
/**
 * 
 * @export
 * @interface AnnotationLayerRequest
 */
export interface AnnotationLayerRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayerRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AnnotationLayerRequestWithAnnotations
 */
export interface AnnotationLayerRequestWithAnnotations {
    /**
     * 
     * @type {string}
     * @memberof AnnotationLayerRequestWithAnnotations
     */
    'name': string;
    /**
     * 
     * @type {Array<AnnotationRequest>}
     * @memberof AnnotationLayerRequestWithAnnotations
     */
    'annotations'?: Array<AnnotationRequest>;
}
/**
 * 
 * @export
 * @interface AnnotationLayerRequestWithAnnotationsAllOf
 */
export interface AnnotationLayerRequestWithAnnotationsAllOf {
    /**
     * 
     * @type {Array<AnnotationRequest>}
     * @memberof AnnotationLayerRequestWithAnnotationsAllOf
     */
    'annotations'?: Array<AnnotationRequest>;
}
/**
 * 
 * @export
 * @interface AnnotationRequest
 */
export interface AnnotationRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequest
     */
    'period_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequest
     */
    'annotation_category_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequest
     */
    'name': string;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof AnnotationRequest
     */
    'start_time_ms': number;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof AnnotationRequest
     */
    'end_time_ms': number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationRequest
     */
    'athlete_ids'?: Array<string>;
    /**
     * The ID of the annotation in the source system, e.g. Focus. Used for deduplication.
     * @type {string}
     * @memberof AnnotationRequest
     */
    'imported_id'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationRequestWithLayerId
 */
export interface AnnotationRequestWithLayerId {
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestWithLayerId
     */
    'period_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestWithLayerId
     */
    'annotation_category_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestWithLayerId
     */
    'name': string;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof AnnotationRequestWithLayerId
     */
    'start_time_ms': number;
    /**
     * Epoch Timestamp, milliseconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof AnnotationRequestWithLayerId
     */
    'end_time_ms': number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestWithLayerId
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationRequestWithLayerId
     */
    'athlete_ids'?: Array<string>;
    /**
     * The ID of the annotation in the source system, e.g. Focus. Used for deduplication.
     * @type {string}
     * @memberof AnnotationRequestWithLayerId
     */
    'imported_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestWithLayerId
     */
    'annotation_layer_id': string;
}
/**
 * 
 * @export
 * @interface AnnotationRequestWithLayerIdAllOf
 */
export interface AnnotationRequestWithLayerIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestWithLayerIdAllOf
     */
    'annotation_layer_id'?: string;
}
/**
 * 
 * @export
 * @interface BaseErrorResponse
 */
export interface BaseErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof BaseErrorResponse
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface BatchDeleteRequest
 */
export interface BatchDeleteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchDeleteRequest
     */
    'ids': Array<string>;
}

/**
 * AnnotationCategoriesApi - axios parameter creator
 * @export
 */
export const AnnotationCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new annotation category
         * @param {AnnotationCategoryRequest} annotationCategoryRequest New annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationCategory: async (annotationCategoryRequest: AnnotationCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationCategoryRequest' is not null or undefined
            assertParamExists('createAnnotationCategory', 'annotationCategoryRequest', annotationCategoryRequest)
            const localVarPath = `/annotation_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all annotations of a category for an activity
         * @param {string} activityId ID of the activity
         * @param {string} annotationCategoryId ID of the annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationsForActivityCategory: async (activityId: string, annotationCategoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteAnnotationsForActivityCategory', 'activityId', activityId)
            // verify required parameter 'annotationCategoryId' is not null or undefined
            assertParamExists('deleteAnnotationsForActivityCategory', 'annotationCategoryId', annotationCategoryId)
            const localVarPath = `/activities/{activityId}/annotation_categories/{annotationCategoryId}/annotations`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"annotationCategoryId"}}`, encodeURIComponent(String(annotationCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all annotation categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an annotation category
         * @param {string} annotationCategoryId ID of the annotation category
         * @param {AnnotationCategoryRequest} annotationCategoryRequest Updated annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCategory: async (annotationCategoryId: string, annotationCategoryRequest: AnnotationCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationCategoryId' is not null or undefined
            assertParamExists('updateAnnotationCategory', 'annotationCategoryId', annotationCategoryId)
            // verify required parameter 'annotationCategoryRequest' is not null or undefined
            assertParamExists('updateAnnotationCategory', 'annotationCategoryRequest', annotationCategoryRequest)
            const localVarPath = `/annotation_categories/{annotationCategoryId}`
                .replace(`{${"annotationCategoryId"}}`, encodeURIComponent(String(annotationCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationCategoriesApi - functional programming interface
 * @export
 */
export const AnnotationCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new annotation category
         * @param {AnnotationCategoryRequest} annotationCategoryRequest New annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotationCategory(annotationCategoryRequest: AnnotationCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotationCategory(annotationCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all annotations of a category for an activity
         * @param {string} activityId ID of the activity
         * @param {string} annotationCategoryId ID of the annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotationsForActivityCategory(activityId: string, annotationCategoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotationsForActivityCategory(activityId, annotationCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all annotation categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an annotation category
         * @param {string} annotationCategoryId ID of the annotation category
         * @param {AnnotationCategoryRequest} annotationCategoryRequest Updated annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotationCategory(annotationCategoryId: string, annotationCategoryRequest: AnnotationCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotationCategory(annotationCategoryId, annotationCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationCategoriesApi - factory interface
 * @export
 */
export const AnnotationCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new annotation category
         * @param {AnnotationCategoryRequest} annotationCategoryRequest New annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationCategory(annotationCategoryRequest: AnnotationCategoryRequest, options?: any): AxiosPromise<AnnotationCategory> {
            return localVarFp.createAnnotationCategory(annotationCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all annotations of a category for an activity
         * @param {string} activityId ID of the activity
         * @param {string} annotationCategoryId ID of the annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationsForActivityCategory(activityId: string, annotationCategoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotationsForActivityCategory(activityId, annotationCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all annotation categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationCategories(options?: any): AxiosPromise<Array<AnnotationCategory>> {
            return localVarFp.getAnnotationCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an annotation category
         * @param {string} annotationCategoryId ID of the annotation category
         * @param {AnnotationCategoryRequest} annotationCategoryRequest Updated annotation category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCategory(annotationCategoryId: string, annotationCategoryRequest: AnnotationCategoryRequest, options?: any): AxiosPromise<AnnotationCategory> {
            return localVarFp.updateAnnotationCategory(annotationCategoryId, annotationCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnotationCategoriesApi - object-oriented interface
 * @export
 * @class AnnotationCategoriesApi
 * @extends {BaseAPI}
 */
export class AnnotationCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new annotation category
     * @param {AnnotationCategoryRequest} annotationCategoryRequest New annotation category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationCategoriesApi
     */
    public createAnnotationCategory(annotationCategoryRequest: AnnotationCategoryRequest, options?: AxiosRequestConfig) {
        return AnnotationCategoriesApiFp(this.configuration).createAnnotationCategory(annotationCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all annotations of a category for an activity
     * @param {string} activityId ID of the activity
     * @param {string} annotationCategoryId ID of the annotation category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationCategoriesApi
     */
    public deleteAnnotationsForActivityCategory(activityId: string, annotationCategoryId: string, options?: AxiosRequestConfig) {
        return AnnotationCategoriesApiFp(this.configuration).deleteAnnotationsForActivityCategory(activityId, annotationCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all annotation categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationCategoriesApi
     */
    public getAnnotationCategories(options?: AxiosRequestConfig) {
        return AnnotationCategoriesApiFp(this.configuration).getAnnotationCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an annotation category
     * @param {string} annotationCategoryId ID of the annotation category
     * @param {AnnotationCategoryRequest} annotationCategoryRequest Updated annotation category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationCategoriesApi
     */
    public updateAnnotationCategory(annotationCategoryId: string, annotationCategoryRequest: AnnotationCategoryRequest, options?: AxiosRequestConfig) {
        return AnnotationCategoriesApiFp(this.configuration).updateAnnotationCategory(annotationCategoryId, annotationCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationLayersApi - axios parameter creator
 * @export
 */
export const AnnotationLayersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new annotation layer and annotations for an activity in a single request
         * @summary Create a new annotation layer and annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationLayerForActivity: async (activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('createAnnotationLayerForActivity', 'activityId', activityId)
            // verify required parameter 'annotationLayerRequestWithAnnotations' is not null or undefined
            assertParamExists('createAnnotationLayerForActivity', 'annotationLayerRequestWithAnnotations', annotationLayerRequestWithAnnotations)
            const localVarPath = `/activities/{activityId}/annotation_layers`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationLayerRequestWithAnnotations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationLayer: async (annotationLayerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationLayerId' is not null or undefined
            assertParamExists('deleteAnnotationLayer', 'annotationLayerId', annotationLayerId)
            const localVarPath = `/annotation_layers/{annotationLayerId}`
                .replace(`{${"annotationLayerId"}}`, encodeURIComponent(String(annotationLayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationLayersForActivity: async (activityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getAnnotationLayersForActivity', 'activityId', activityId)
            const localVarPath = `/activities/{activityId}/annotation_layers`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an Annotation Layer
         * @param {string} annotationLayerId ID of the annotation layer
         * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationLayer: async (annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationLayerId' is not null or undefined
            assertParamExists('updateAnnotationLayer', 'annotationLayerId', annotationLayerId)
            // verify required parameter 'annotationLayerRequest' is not null or undefined
            assertParamExists('updateAnnotationLayer', 'annotationLayerRequest', annotationLayerRequest)
            const localVarPath = `/annotation_layers/{annotationLayerId}`
                .replace(`{${"annotationLayerId"}}`, encodeURIComponent(String(annotationLayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationLayerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationLayersApi - functional programming interface
 * @export
 */
export const AnnotationLayersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationLayersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new annotation layer and annotations for an activity in a single request
         * @summary Create a new annotation layer and annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotationLayerForActivity(activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationLayer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotationLayerForActivity(activityId, annotationLayerRequestWithAnnotations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotationLayer(annotationLayerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotationLayer(annotationLayerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationLayersForActivity(activityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationLayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationLayersForActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an Annotation Layer
         * @param {string} annotationLayerId ID of the annotation layer
         * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotationLayer(annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationLayer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotationLayer(annotationLayerId, annotationLayerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationLayersApi - factory interface
 * @export
 */
export const AnnotationLayersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationLayersApiFp(configuration)
    return {
        /**
         * Create a new annotation layer and annotations for an activity in a single request
         * @summary Create a new annotation layer and annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationLayerForActivity(activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options?: any): AxiosPromise<AnnotationLayer> {
            return localVarFp.createAnnotationLayerForActivity(activityId, annotationLayerRequestWithAnnotations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationLayer(annotationLayerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotationLayer(annotationLayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationLayersForActivity(activityId: string, options?: any): AxiosPromise<Array<AnnotationLayer>> {
            return localVarFp.getAnnotationLayersForActivity(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an Annotation Layer
         * @param {string} annotationLayerId ID of the annotation layer
         * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationLayer(annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options?: any): AxiosPromise<AnnotationLayer> {
            return localVarFp.updateAnnotationLayer(annotationLayerId, annotationLayerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnotationLayersApi - object-oriented interface
 * @export
 * @class AnnotationLayersApi
 * @extends {BaseAPI}
 */
export class AnnotationLayersApi extends BaseAPI {
    /**
     * Create a new annotation layer and annotations for an activity in a single request
     * @summary Create a new annotation layer and annotations for an activity
     * @param {string} activityId ID of the activity
     * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationLayersApi
     */
    public createAnnotationLayerForActivity(activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options?: AxiosRequestConfig) {
        return AnnotationLayersApiFp(this.configuration).createAnnotationLayerForActivity(activityId, annotationLayerRequestWithAnnotations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an annotation layer and all its annotations
     * @param {string} annotationLayerId ID of the annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationLayersApi
     */
    public deleteAnnotationLayer(annotationLayerId: string, options?: AxiosRequestConfig) {
        return AnnotationLayersApiFp(this.configuration).deleteAnnotationLayer(annotationLayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annotation layers for an activity
     * @param {string} activityId ID of the activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationLayersApi
     */
    public getAnnotationLayersForActivity(activityId: string, options?: AxiosRequestConfig) {
        return AnnotationLayersApiFp(this.configuration).getAnnotationLayersForActivity(activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an Annotation Layer
     * @param {string} annotationLayerId ID of the annotation layer
     * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationLayersApi
     */
    public updateAnnotationLayer(annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options?: AxiosRequestConfig) {
        return AnnotationLayersApiFp(this.configuration).updateAnnotationLayer(annotationLayerId, annotationLayerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationsApi - axios parameter creator
 * @export
 */
export const AnnotationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new annotation
         * @param {string} activityId ID of the activity
         * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotation: async (activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('createAnnotation', 'activityId', activityId)
            // verify required parameter 'annotationRequestWithLayerId' is not null or undefined
            assertParamExists('createAnnotation', 'annotationRequestWithLayerId', annotationRequestWithLayerId)
            const localVarPath = `/activities/{activityId}/annotations`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequestWithLayerId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an annotation
         * @param {string} annotationId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation: async (annotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationId' is not null or undefined
            assertParamExists('deleteAnnotation', 'annotationId', annotationId)
            const localVarPath = `/annotations/{annotationId}`
                .replace(`{${"annotationId"}}`, encodeURIComponent(String(annotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple annotations in a single request
         * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotations: async (batchDeleteRequest: BatchDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchDeleteRequest' is not null or undefined
            assertParamExists('deleteAnnotations', 'batchDeleteRequest', batchDeleteRequest)
            const localVarPath = `/annotations/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationsForActivity: async (activityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getAnnotationsForActivity', 'activityId', activityId)
            const localVarPath = `/activities/{activityId}/annotations`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an annotation
         * @param {string} annotationId ID of the annotation
         * @param {AnnotationRequest} annotationRequest Updated annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotation: async (annotationId: string, annotationRequest: AnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationId' is not null or undefined
            assertParamExists('updateAnnotation', 'annotationId', annotationId)
            // verify required parameter 'annotationRequest' is not null or undefined
            assertParamExists('updateAnnotation', 'annotationRequest', annotationRequest)
            const localVarPath = `/annotations/{annotationId}`
                .replace(`{${"annotationId"}}`, encodeURIComponent(String(annotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the athletes associated with an annotation
         * @param {string} annotationId ID of the annotation
         * @param {Array<string>} requestBody Updated athletes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationAthletes: async (annotationId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationId' is not null or undefined
            assertParamExists('updateAnnotationAthletes', 'annotationId', annotationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateAnnotationAthletes', 'requestBody', requestBody)
            const localVarPath = `/annotations/{annotationId}/athletes`
                .replace(`{${"annotationId"}}`, encodeURIComponent(String(annotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationsApi - functional programming interface
 * @export
 */
export const AnnotationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new annotation
         * @param {string} activityId ID of the activity
         * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotation(activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotation(activityId, annotationRequestWithLayerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an annotation
         * @param {string} annotationId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotation(annotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotation(annotationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete multiple annotations in a single request
         * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotations(batchDeleteRequest: BatchDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotations(batchDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationsForActivity(activityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Annotation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationsForActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an annotation
         * @param {string} annotationId ID of the annotation
         * @param {AnnotationRequest} annotationRequest Updated annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotation(annotationId: string, annotationRequest: AnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotation(annotationId, annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the athletes associated with an annotation
         * @param {string} annotationId ID of the annotation
         * @param {Array<string>} requestBody Updated athletes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotationAthletes(annotationId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotationAthletes(annotationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationsApi - factory interface
 * @export
 */
export const AnnotationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new annotation
         * @param {string} activityId ID of the activity
         * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotation(activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options?: any): AxiosPromise<Annotation> {
            return localVarFp.createAnnotation(activityId, annotationRequestWithLayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an annotation
         * @param {string} annotationId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation(annotationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotation(annotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple annotations in a single request
         * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotations(batchDeleteRequest: BatchDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotations(batchDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationsForActivity(activityId: string, options?: any): AxiosPromise<Array<Annotation>> {
            return localVarFp.getAnnotationsForActivity(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an annotation
         * @param {string} annotationId ID of the annotation
         * @param {AnnotationRequest} annotationRequest Updated annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotation(annotationId: string, annotationRequest: AnnotationRequest, options?: any): AxiosPromise<Annotation> {
            return localVarFp.updateAnnotation(annotationId, annotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the athletes associated with an annotation
         * @param {string} annotationId ID of the annotation
         * @param {Array<string>} requestBody Updated athletes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationAthletes(annotationId: string, requestBody: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.updateAnnotationAthletes(annotationId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnotationsApi - object-oriented interface
 * @export
 * @class AnnotationsApi
 * @extends {BaseAPI}
 */
export class AnnotationsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new annotation
     * @param {string} activityId ID of the activity
     * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public createAnnotation(activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).createAnnotation(activityId, annotationRequestWithLayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an annotation
     * @param {string} annotationId ID of the annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public deleteAnnotation(annotationId: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).deleteAnnotation(annotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple annotations in a single request
     * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public deleteAnnotations(batchDeleteRequest: BatchDeleteRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).deleteAnnotations(batchDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annotations for an activity
     * @param {string} activityId ID of the activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getAnnotationsForActivity(activityId: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getAnnotationsForActivity(activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an annotation
     * @param {string} annotationId ID of the annotation
     * @param {AnnotationRequest} annotationRequest Updated annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public updateAnnotation(annotationId: string, annotationRequest: AnnotationRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).updateAnnotation(annotationId, annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the athletes associated with an annotation
     * @param {string} annotationId ID of the annotation
     * @param {Array<string>} requestBody Updated athletes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public updateAnnotationAthletes(annotationId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).updateAnnotationAthletes(annotationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportersApi - axios parameter creator
 * @export
 */
export const ImportersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create multiple annotation categories in a single request
         * @summary Create annotation categories
         * @param {Array<AnnotationCategoryRequest>} annotationCategoryRequest Annotation categories to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationCategories: async (annotationCategoryRequest: Array<AnnotationCategoryRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationCategoryRequest' is not null or undefined
            assertParamExists('createAnnotationCategories', 'annotationCategoryRequest', annotationCategoryRequest)
            const localVarPath = `/annotation_categories/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'text/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new annotation layer and annotations for an activity in a single request
         * @summary Create a new annotation layer and annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationLayerForActivity: async (activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('createAnnotationLayerForActivity', 'activityId', activityId)
            // verify required parameter 'annotationLayerRequestWithAnnotations' is not null or undefined
            assertParamExists('createAnnotationLayerForActivity', 'annotationLayerRequestWithAnnotations', annotationLayerRequestWithAnnotations)
            const localVarPath = `/activities/{activityId}/annotation_layers`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationLayerRequestWithAnnotations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationLayer: async (annotationLayerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationLayerId' is not null or undefined
            assertParamExists('deleteAnnotationLayer', 'annotationLayerId', annotationLayerId)
            const localVarPath = `/annotation_layers/{annotationLayerId}`
                .replace(`{${"annotationLayerId"}}`, encodeURIComponent(String(annotationLayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all annotation categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationLayersForActivity: async (activityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getAnnotationLayersForActivity', 'activityId', activityId)
            const localVarPath = `/activities/{activityId}/annotation_layers`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportersApi - functional programming interface
 * @export
 */
export const ImportersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create multiple annotation categories in a single request
         * @summary Create annotation categories
         * @param {Array<AnnotationCategoryRequest>} annotationCategoryRequest Annotation categories to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotationCategories(annotationCategoryRequest: Array<AnnotationCategoryRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotationCategories(annotationCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new annotation layer and annotations for an activity in a single request
         * @summary Create a new annotation layer and annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotationLayerForActivity(activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationLayer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotationLayerForActivity(activityId, annotationLayerRequestWithAnnotations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotationLayer(annotationLayerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotationLayer(annotationLayerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all annotation categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationLayersForActivity(activityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationLayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationLayersForActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportersApi - factory interface
 * @export
 */
export const ImportersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportersApiFp(configuration)
    return {
        /**
         * Create multiple annotation categories in a single request
         * @summary Create annotation categories
         * @param {Array<AnnotationCategoryRequest>} annotationCategoryRequest Annotation categories to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationCategories(annotationCategoryRequest: Array<AnnotationCategoryRequest>, options?: any): AxiosPromise<Array<AnnotationCategory>> {
            return localVarFp.createAnnotationCategories(annotationCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new annotation layer and annotations for an activity in a single request
         * @summary Create a new annotation layer and annotations for an activity
         * @param {string} activityId ID of the activity
         * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotationLayerForActivity(activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options?: any): AxiosPromise<AnnotationLayer> {
            return localVarFp.createAnnotationLayerForActivity(activityId, annotationLayerRequestWithAnnotations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationLayer(annotationLayerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotationLayer(annotationLayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all annotation categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationCategories(options?: any): AxiosPromise<Array<AnnotationCategory>> {
            return localVarFp.getAnnotationCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationLayersForActivity(activityId: string, options?: any): AxiosPromise<Array<AnnotationLayer>> {
            return localVarFp.getAnnotationLayersForActivity(activityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportersApi - object-oriented interface
 * @export
 * @class ImportersApi
 * @extends {BaseAPI}
 */
export class ImportersApi extends BaseAPI {
    /**
     * Create multiple annotation categories in a single request
     * @summary Create annotation categories
     * @param {Array<AnnotationCategoryRequest>} annotationCategoryRequest Annotation categories to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportersApi
     */
    public createAnnotationCategories(annotationCategoryRequest: Array<AnnotationCategoryRequest>, options?: AxiosRequestConfig) {
        return ImportersApiFp(this.configuration).createAnnotationCategories(annotationCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new annotation layer and annotations for an activity in a single request
     * @summary Create a new annotation layer and annotations for an activity
     * @param {string} activityId ID of the activity
     * @param {AnnotationLayerRequestWithAnnotations} annotationLayerRequestWithAnnotations New annotation layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportersApi
     */
    public createAnnotationLayerForActivity(activityId: string, annotationLayerRequestWithAnnotations: AnnotationLayerRequestWithAnnotations, options?: AxiosRequestConfig) {
        return ImportersApiFp(this.configuration).createAnnotationLayerForActivity(activityId, annotationLayerRequestWithAnnotations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an annotation layer and all its annotations
     * @param {string} annotationLayerId ID of the annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportersApi
     */
    public deleteAnnotationLayer(annotationLayerId: string, options?: AxiosRequestConfig) {
        return ImportersApiFp(this.configuration).deleteAnnotationLayer(annotationLayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all annotation categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportersApi
     */
    public getAnnotationCategories(options?: AxiosRequestConfig) {
        return ImportersApiFp(this.configuration).getAnnotationCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annotation layers for an activity
     * @param {string} activityId ID of the activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportersApi
     */
    public getAnnotationLayersForActivity(activityId: string, options?: AxiosRequestConfig) {
        return ImportersApiFp(this.configuration).getAnnotationLayersForActivity(activityId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebEditorApi - axios parameter creator
 * @export
 */
export const WebEditorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new annotation
         * @param {string} activityId ID of the activity
         * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotation: async (activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('createAnnotation', 'activityId', activityId)
            // verify required parameter 'annotationRequestWithLayerId' is not null or undefined
            assertParamExists('createAnnotation', 'annotationRequestWithLayerId', annotationRequestWithLayerId)
            const localVarPath = `/activities/{activityId}/annotations`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequestWithLayerId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an annotation
         * @param {string} annotationId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation: async (annotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationId' is not null or undefined
            assertParamExists('deleteAnnotation', 'annotationId', annotationId)
            const localVarPath = `/annotations/{annotationId}`
                .replace(`{${"annotationId"}}`, encodeURIComponent(String(annotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationLayer: async (annotationLayerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationLayerId' is not null or undefined
            assertParamExists('deleteAnnotationLayer', 'annotationLayerId', annotationLayerId)
            const localVarPath = `/annotation_layers/{annotationLayerId}`
                .replace(`{${"annotationLayerId"}}`, encodeURIComponent(String(annotationLayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple annotations in a single request
         * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotations: async (batchDeleteRequest: BatchDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchDeleteRequest' is not null or undefined
            assertParamExists('deleteAnnotations', 'batchDeleteRequest', batchDeleteRequest)
            const localVarPath = `/annotations/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationLayersForActivity: async (activityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getAnnotationLayersForActivity', 'activityId', activityId)
            const localVarPath = `/activities/{activityId}/annotation_layers`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an annotation
         * @param {string} annotationId ID of the annotation
         * @param {AnnotationRequest} annotationRequest Updated annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotation: async (annotationId: string, annotationRequest: AnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationId' is not null or undefined
            assertParamExists('updateAnnotation', 'annotationId', annotationId)
            // verify required parameter 'annotationRequest' is not null or undefined
            assertParamExists('updateAnnotation', 'annotationRequest', annotationRequest)
            const localVarPath = `/annotations/{annotationId}`
                .replace(`{${"annotationId"}}`, encodeURIComponent(String(annotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an Annotation Layer
         * @param {string} annotationLayerId ID of the annotation layer
         * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationLayer: async (annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annotationLayerId' is not null or undefined
            assertParamExists('updateAnnotationLayer', 'annotationLayerId', annotationLayerId)
            // verify required parameter 'annotationLayerRequest' is not null or undefined
            assertParamExists('updateAnnotationLayer', 'annotationLayerRequest', annotationLayerRequest)
            const localVarPath = `/annotation_layers/{annotationLayerId}`
                .replace(`{${"annotationLayerId"}}`, encodeURIComponent(String(annotationLayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationLayerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebEditorApi - functional programming interface
 * @export
 */
export const WebEditorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebEditorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new annotation
         * @param {string} activityId ID of the activity
         * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotation(activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotation(activityId, annotationRequestWithLayerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an annotation
         * @param {string} annotationId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotation(annotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotation(annotationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotationLayer(annotationLayerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotationLayer(annotationLayerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete multiple annotations in a single request
         * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotations(batchDeleteRequest: BatchDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotations(batchDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationLayersForActivity(activityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationLayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationLayersForActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an annotation
         * @param {string} annotationId ID of the annotation
         * @param {AnnotationRequest} annotationRequest Updated annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotation(annotationId: string, annotationRequest: AnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotation(annotationId, annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an Annotation Layer
         * @param {string} annotationLayerId ID of the annotation layer
         * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotationLayer(annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationLayer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotationLayer(annotationLayerId, annotationLayerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebEditorApi - factory interface
 * @export
 */
export const WebEditorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebEditorApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new annotation
         * @param {string} activityId ID of the activity
         * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotation(activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options?: any): AxiosPromise<Annotation> {
            return localVarFp.createAnnotation(activityId, annotationRequestWithLayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an annotation
         * @param {string} annotationId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation(annotationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotation(annotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an annotation layer and all its annotations
         * @param {string} annotationLayerId ID of the annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationLayer(annotationLayerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotationLayer(annotationLayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple annotations in a single request
         * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotations(batchDeleteRequest: BatchDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotations(batchDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annotation layers for an activity
         * @param {string} activityId ID of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationLayersForActivity(activityId: string, options?: any): AxiosPromise<Array<AnnotationLayer>> {
            return localVarFp.getAnnotationLayersForActivity(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an annotation
         * @param {string} annotationId ID of the annotation
         * @param {AnnotationRequest} annotationRequest Updated annotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotation(annotationId: string, annotationRequest: AnnotationRequest, options?: any): AxiosPromise<Annotation> {
            return localVarFp.updateAnnotation(annotationId, annotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an Annotation Layer
         * @param {string} annotationLayerId ID of the annotation layer
         * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationLayer(annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options?: any): AxiosPromise<AnnotationLayer> {
            return localVarFp.updateAnnotationLayer(annotationLayerId, annotationLayerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebEditorApi - object-oriented interface
 * @export
 * @class WebEditorApi
 * @extends {BaseAPI}
 */
export class WebEditorApi extends BaseAPI {
    /**
     * 
     * @summary Create a new annotation
     * @param {string} activityId ID of the activity
     * @param {AnnotationRequestWithLayerId} annotationRequestWithLayerId New annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public createAnnotation(activityId: string, annotationRequestWithLayerId: AnnotationRequestWithLayerId, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).createAnnotation(activityId, annotationRequestWithLayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an annotation
     * @param {string} annotationId ID of the annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public deleteAnnotation(annotationId: string, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).deleteAnnotation(annotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an annotation layer and all its annotations
     * @param {string} annotationLayerId ID of the annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public deleteAnnotationLayer(annotationLayerId: string, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).deleteAnnotationLayer(annotationLayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple annotations in a single request
     * @param {BatchDeleteRequest} batchDeleteRequest Annotation IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public deleteAnnotations(batchDeleteRequest: BatchDeleteRequest, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).deleteAnnotations(batchDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annotation layers for an activity
     * @param {string} activityId ID of the activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public getAnnotationLayersForActivity(activityId: string, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).getAnnotationLayersForActivity(activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an annotation
     * @param {string} annotationId ID of the annotation
     * @param {AnnotationRequest} annotationRequest Updated annotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public updateAnnotation(annotationId: string, annotationRequest: AnnotationRequest, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).updateAnnotation(annotationId, annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an Annotation Layer
     * @param {string} annotationLayerId ID of the annotation layer
     * @param {AnnotationLayerRequest} annotationLayerRequest Updated annotation layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebEditorApi
     */
    public updateAnnotationLayer(annotationLayerId: string, annotationLayerRequest: AnnotationLayerRequest, options?: AxiosRequestConfig) {
        return WebEditorApiFp(this.configuration).updateAnnotationLayer(annotationLayerId, annotationLayerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


